import Vue from "vue"
// import productYunResourceView from "product-yun-resource-view";
import productYunsampleView from "product-yun-sample-view";
Vue.use(productYunsampleView);

// import { LaboratoryQualification, TestObject, TestPersonnel, TestEquipment, TestStandard, TestFeeStandard, TestDurationStandard, TestScheme } from "product-yun-resource-view";
import { samplesStoreroom,sampleManagement } from "product-yun-sample-view"

import productYunQuoteView from "product-yun-quote-view";
Vue.use(productYunQuoteView);
// import { QuoteManage } from "../../../../product-yun-quote-view"
import { QuoteManage } from "product-yun-quote-view"


/** 资源 */
const routes = [
    // 实验室资质
    {
        path: "/QuoteManage",
        name: "QuoteManage",
        component: QuoteManage,
        meta: {
            title: "报价管理",
            groupName: "报价",
            icon: "HomePage",
        },
    },
    {
		path: "/sampleManagement",
		name: "sampleManagement",
		component: sampleManagement,
		meta: {
			title: "样品管理",
			groupName: '样品',
			icon: "SampleManagement",
		},
	},
	// {
	// 	path: "/sampleCirculation",
	// 	name: "sampleCirculation",
	// 	component: sampleCirculation,
	// 	meta: {
	// 		title: "样品流转",
    //         groupName: '样品',
	// 		icon: "HomePage",
	// 	},
	// },
	{
		path: "/samplesStoreroom",
		name: "samplesStoreroom",
		component: samplesStoreroom,
		meta: {
			title: "留样库",
            groupName: '样品',
			icon: "SamplesStoreroom",
		},
	},
]

export default routes
